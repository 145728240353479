import React from "react";

export default function Input({ label, errorMsg, type, ...props }) {
  return (
    <div className="flex flex-col py-10 ">
      <label className="text-3xl font-bold" for={label}>
        {label}
      </label>
      {type !== "textarea" && (
        <input
          className="bg-white bg-opacity-75 rounded-lg border border-white text-3xl focus:ring-0 outline-none p-3 text-black"
          id={label}
          {...props}
        />
      )}
      {type === "textarea" && (
        <textarea
          className="bg-transparent bg-white rounded-lg bg-opacity-75 border border-white text-3xl focus:ring-0 outline-none p-3 text-black"
          id={label}
          {...props}
        />
      )}
      <p className="text-red-400">{errorMsg}</p>
    </div>
  );
}
