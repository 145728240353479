import React, { useState } from "react";

import Layout from "../components/layout";
import { useStaticQuery, graphql } from "gatsby";
import SocialMedia from "../components/social-media";
import Img from "gatsby-image";

import Input from "../components/input";

export default function Contact({ location }) {
  const data = useStaticQuery(graphql`
    {
      background: file(relativePath: { eq: "sunset-3.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 600, maxWidth: 2560) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const background = data["background"].childImageSharp.fluid;

  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({
    email: "",
    subject: "",
    message: "",
  });

  const submit = (event) => {
    event.preventDefault();
    let tempErrors = errors;
    tempErrors.email =
      email === "" ||
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
        ? "Please enter a valid email"
        : "";
    tempErrors.subject = subject === "" ? "Please enter a valid subject" : "";
    tempErrors.message = message === "" ? "Please enter a valid message" : "";

    if (Object.values(tempErrors).some((err) => err !== "")) {
      setErrors({ ...tempErrors });
      return;
    }

    setLoading(true);
    var url = new URL(
      location.origin + "/.netlify/functions/send-contact-email?"
    );

    const params = {
      email: email,
      subject: subject,
      message: message,
    };
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        setEmail("");
        setMessage("");
        setSubject("");
        setLoading(false);
      });
  };

  return (
    <Layout>
      <h1 className="text-center text-5xl py-10">Contact</h1>
      <div className="relative px-5">
        <div className="absolute inset-0 -z-1">
          <Img
            fluid={background}
            className="h-full"
            imgStyle={{ filter: "brightness(0.5)", objectPosition: "0 0" }}
          />
        </div>
        <SocialMedia className="flex flex-col sm:flex-row sm:justify-around" />
        <form
          className="max-w-3xl m-auto pb-20"
          onSubmit={submit}
          onChange={() => setErrors({})}
        >
          <Input
            label="Your email:"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            errorMsg={errors["email"]}
          />
          <Input
            label="Subject:"
            value={subject}
            onChange={(event) => setSubject(event.target.value)}
            errorMsg={errors["subject"]}
          />
          <Input
            label="Message:"
            value={message}
            onChange={(event) => setMessage(event.target.value)}
            errorMsg={errors["message"]}
            type="textarea"
          />
          <button
            type="submit"
            className="rounded-lg border border-white text-3xl px-10 py-5 outline-none focus:ring-0"
            disabled={loading}
          >
            {loading ? "loading" : "Send"}
          </button>
        </form>
      </div>
    </Layout>
  );
}
